import React from 'react';

import styles from './Logo.module.css';

import logo from 'assets/logo.svg';
import logoWhiteBg from 'assets/logoWhiteBg.svg';

type LogoProps = {
  showXs?: boolean;
  containerStyles?: React.CSSProperties;
};

function Logo(props: LogoProps) {
  const { showXs } = props;
  const { image, overlayImage, visibleImage, hiddenImage } = styles;
  const { container } = styles;

  const logoWhiteBgClasses = `${image} ${showXs ? visibleImage : hiddenImage}`;
  const logoNormalBgClasses = `${image} ${overlayImage} ${
    showXs ? hiddenImage : visibleImage
  }`;

  const additionalContainerStyles = { ...props.containerStyles };

  return (
    <div className={container} style={additionalContainerStyles}>
      <img className={logoWhiteBgClasses} src={logoWhiteBg} alt={logoWhiteBg} />
      <img className={logoNormalBgClasses} src={logo} alt={logo} />
    </div>
  );
}

export default Logo;
