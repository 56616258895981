import React from 'react';

import styles from 'components/FullScreenSection/ImageSectionHalf/ImageSectionHalf.module.css';

type ImageSectionHalfProps = {
  image?: string;
  video?: string;
};

function ImageSectionHalf(props: ImageSectionHalfProps) {
  const { image, video } = props;
  const { sectionImageContainer, sectionImage } = styles;

  let content;
  if (image) {
    content = <img src={image} alt={image} className={sectionImage} />;
  } else if (video) {
    content = (
      <video className={sectionImage} autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </video>
    );
  }
  return <div className={sectionImageContainer}>{content}</div>;
}

export default ImageSectionHalf;
