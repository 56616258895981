import DownloadAppStore from 'assets/DownloadAppStore.svg';
import DownloadPlayStore from 'assets/DownloadPlayStore.svg';
import overviewPreview from 'assets/appPreview/Overview.png';
import FullScreenSection from 'components/FullScreenSection';
import ImageSectionHalf from 'components/FullScreenSection/ImageSectionHalf';
import Logo from 'components/Logo';
import React from 'react';
import styles from 'pages/Home/HomeTopSection/styles.module.css';
import { useInView } from 'react-intersection-observer';

type HomeTopSectionProps = {
  titleColor: string;
  descriptionColor: string;
  backgroundColor: string;
  updateShouldShowLogoHeader: (shouldShowLogoHeader: boolean) => void;
};

const TITLE = 'The best way to plan a trip locally';
const DESCRIPTION =
  'Plan quick getaways at the tips of your fingers. Have your own personal guide to venture into the places you want to visit and check out the best ways to travel, alone or with company.';

function HomeTopSection(props: HomeTopSectionProps) {
  const { ref, inView } = useInView({
    initialInView: true,
  });

  props.updateShouldShowLogoHeader(!inView);

  const storeLinks = [DownloadAppStore, DownloadPlayStore].map((value) => (
    <div className={styles.storeLinkContainer}>
      <img src={value} alt={value} className={styles.storeLinkImage} />
    </div>
  ));

  const detailsContent = (
    <>
      <div ref={ref} className={styles.mdGutter}>
        <Logo
          showXs={true}
          containerStyles={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        />
      </div>
      <div
        className={`${styles.mdGutter} ${styles.mainHeading}`}
        style={{
          color: props.titleColor,
        }}
      >
        {TITLE}
      </div>
      <div
        className={styles.detailText}
        style={{
          color: props.descriptionColor,
        }}
      >
        {DESCRIPTION}
      </div>

      {storeLinks}
    </>
  );

  return (
    <FullScreenSection
      backgroundColor={props.backgroundColor}
      leftContent={detailsContent}
      rightContent={<ImageSectionHalf image={overviewPreview} />}
    />
  );
}

export default HomeTopSection;
