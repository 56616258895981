import React, { PropsWithChildren } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export type StaticPageProps = {};

const StaticPage: React.FC<PropsWithChildren<StaticPageProps>> = (props) => {
  return (
    <Row className={'justify-content-center gx-0'}>
      <Col xs sm={11} md={8} lg={6} xxl={5}>
        {props.children}
      </Col>
    </Row>
  );
};

export default StaticPage;
