import { ReactBootstrapConstants } from 'constants/ReactBootstrapConstants';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import { RouterConstants } from 'constants/RouterConstants';

import styles from './Footer.module.css';

const TRIPTAGE_CONTACT_US_EMAIL = 'hi@triptage.com';

function Footer() {
  return (
    <Row
      className={`${ReactBootstrapConstants.JUSTIFY_CONTENT_CENTER} ${styles.container} gx-0`}
    >
      <Col xs sm={10}>
        <Row className="gx-0">
          <Col>
            <Row className="gx-0">
              <div className={styles.sectionHeader}>
                <b>Contact:</b>
              </div>
              <div>
                <a
                  href={`mailto:${TRIPTAGE_CONTACT_US_EMAIL}`}
                  className={styles.link}
                >
                  {TRIPTAGE_CONTACT_US_EMAIL}
                </a>
              </div>
            </Row>
          </Col>
          <Col>
            <Row className="gx-0">
              <div className={styles.sectionHeader}>
                <b>Legal</b>
              </div>
              <div>
                <Link
                  to={RouterConstants.TERMS_AND_CONDITIONS_PATH}
                  className={styles.link}
                >
                  {RouterConstants.TERMS_AND_CONDITIONS_LINK_NAME}
                </Link>
              </div>
              <div>
                <Link
                  to={RouterConstants.PRIVACY_POLICY_PATH}
                  className={styles.link}
                >
                  {RouterConstants.PRIVACY_POLICY_LINK_NAME}
                </Link>
              </div>
            </Row>
          </Col>
          <Col>
            <Row className="gx-0">
              <div className={styles.sectionHeader}>
                <b>Download Mobile App</b>
              </div>
              {/*TODO*/}
              <a href="/TODO" className={styles.link}>
                Google Play Store
              </a>
              {/*TODO*/}
              <a href="/TODO" className={styles.link}>
                App Store
              </a>
            </Row>
          </Col>
        </Row>
        <div className={styles.storeLegalText}>
          <div>iPhone® and App Store® are trademarks of Apple Inc.</div>
          <div>
            Google Play and the Google Play logo are trademarks of Google LLC.
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Footer;
