import ImageSectionHalf from 'components/FullScreenSection/ImageSectionHalf';
import React from 'react';

import FullScreenSection from 'components/FullScreenSection/index';

import styles from 'components/FullScreenSection/DescriptionWithImageFullScreenSection/DescriptionWithImageFullScreenSection.module.css';

type DescriptionWithImageFullScreenSectionProps = {
  image?: string;
  video?: string;
  title: string;
  descriptions: string[];
  backgroundColor: string;
  titleColor?: string;
  descriptionColor?: string;
  imageOnRight?: boolean;
};

function DescriptionWithImageFullScreenSection(
  props: DescriptionWithImageFullScreenSectionProps
) {
  const {
    image,
    title,
    descriptions,
    backgroundColor,
    titleColor,
    descriptionColor,
    imageOnRight,
  } = props;

  const { sectionDetailsTitleContainer, sectionDetailsDescriptionsContainer } =
    styles;

  const descriptionsToRender: JSX.Element[] = [];
  descriptions.forEach((value, index) => {
    const description = <div>{value}</div>;
    descriptionsToRender.push(description);

    if (index !== descriptions.length - 1) {
      descriptionsToRender.push(<br />);
    }
  });

  const imageContent = <ImageSectionHalf image={image} video={props.video} />;

  const detailsContent = (
    <>
      <div
        className={sectionDetailsTitleContainer}
        style={{ ...(titleColor && { color: titleColor }) }}
      >
        {title}
      </div>
      <div
        className={sectionDetailsDescriptionsContainer}
        style={{ ...(descriptionColor && { color: descriptionColor }) }}
      >
        {descriptionsToRender}
      </div>
    </>
  );

  return (
    <FullScreenSection
      backgroundColor={backgroundColor}
      leftContent={imageOnRight ? detailsContent : imageContent}
      rightContent={imageOnRight ? imageContent : detailsContent}
    />
  );
}

export default DescriptionWithImageFullScreenSection;
