import Logo from 'components/Logo';
import React from 'react';
import Col from 'react-bootstrap/Col';

import styles from './LogoHeader.module.css';

type LogoHeaderProps = {
  hide?: boolean;
};

function LogoHeader(props: LogoHeaderProps) {
  return (
    <Col
      xs={2}
      sm={2}
      className={styles.container}
      style={{
        opacity: props.hide ? 0 : 1,
      }}
    >
      <Col
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xxl={4}
        className={styles.logoContainer}
      >
        <Logo
          showXs={true}
          containerStyles={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        />
      </Col>
    </Col>
  );
}

export default LogoHeader;
