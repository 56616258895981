import tripWithMapPreview from 'assets/appPreview/TripWithMap.png';
import bucketlistPreview from 'assets/appPreview/Bucketlist.png';
import previewVideo from 'assets/appPreview.mp4';

import DescriptionWithImageFullScreenSection from 'components/FullScreenSection/DescriptionWithImageFullScreenSection';
import Layout from 'components/Layout';
import LogoHeader from 'components/LogoHeader';
import HomeTopSection from 'pages/Home/HomeTopSection';
import React, { useState } from 'react';

type SectionTheme = {
  backgroundColor: string;
  titleColor: string;
  descriptionColor: string;
};

const SECTION_THEME_BLUE_BG: SectionTheme = {
  backgroundColor: '#2D70DF',
  titleColor: '#FFFFFF',
  descriptionColor: '#FFFFFF',
};

const SECTION_THEME_PURPLE_BG: SectionTheme = {
  backgroundColor: '#6642CC',
  titleColor: '#FFFFFF',
  descriptionColor: '#FFFFFF',
};

const SECTION_1_TITLE = 'Discover trips';
const SECTION_1_DESCRIPTIONS = [
  "Take a page out from someone's travel diary. Find itineraries with details about routes, flights, where to stay, places to see, foods to try out, and the best times to visit.",
];
const SECTION_1_THEME = SECTION_THEME_BLUE_BG;

const SECTION_2_TITLE = 'Whimsical Delights';
const SECTION_2_DESCRIPTIONS = [
  "Follow your heart and let it run wild. Explore the things and activities you like and add them to your trips so that you don't miss out on any opportunity.",
];
const SECTION_2_THEME = SECTION_THEME_PURPLE_BG;

const SECTION_3_TITLE = 'Savor your memories';
const SECTION_3_DESCRIPTIONS = [
  'The Bucketlist feature allows you to save all your memories so that you can take a trip down the memory lane without having to remember tiny little details.',
  'Save them privately or share with your friends to help them experience the same joy.',
];
const SECTION_3_THEME = SECTION_THEME_BLUE_BG;

function Home() {
  const [shouldShowLogoHeader, setShowLogoHeader] = useState(false);

  return (
    <Layout
      hideHeader={true}
      header={<LogoHeader hide={!shouldShowLogoHeader} />}
    >
      <HomeTopSection
        updateShouldShowLogoHeader={(showLogoHeader) =>
          setShowLogoHeader(showLogoHeader)
        }
        {...SECTION_THEME_PURPLE_BG}
      />
      <DescriptionWithImageFullScreenSection
        video={previewVideo}
        title={SECTION_1_TITLE}
        descriptions={SECTION_1_DESCRIPTIONS}
        {...SECTION_1_THEME}
      />
      <DescriptionWithImageFullScreenSection
        image={tripWithMapPreview}
        title={SECTION_2_TITLE}
        descriptions={SECTION_2_DESCRIPTIONS}
        imageOnRight
        {...SECTION_2_THEME}
      />
      <DescriptionWithImageFullScreenSection
        image={bucketlistPreview}
        title={SECTION_3_TITLE}
        descriptions={SECTION_3_DESCRIPTIONS}
        {...SECTION_3_THEME}
      />
    </Layout>
  );
}

export default Home;
