import { ReactBootstrapConstants } from 'constants/ReactBootstrapConstants';
import React, { PropsWithChildren, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import styles from 'components/FullScreenSection/FullScreenSection.module.css';

type FullScreenSectionProps = {
  rightContent: ReactNode;
  leftContent: ReactNode;
  backgroundColor: string;
};

const FullScreenSection: React.FC<PropsWithChildren<FullScreenSectionProps>> = (
  props
) => {
  const { backgroundColor, leftContent, rightContent } = props;
  const { sectionDetailsContainer, sectionContainer, sectionDetailsWrapper } =
    styles;

  return (
    <Row
      className={`${ReactBootstrapConstants.ALIGN_ITEMS_CENTER} ${ReactBootstrapConstants.JUSTIFY_CONTENT_CENTER} ${sectionContainer} gx-0`}
      style={{ background: backgroundColor }}
    >
      <Col
        xs={10}
        sm={10}
        md={8}
        lg={8}
        xl={8}
        xxl={6}
        className={sectionDetailsWrapper}
      >
        <Row
          className={`${ReactBootstrapConstants.ALIGN_ITEMS_CENTER} ${ReactBootstrapConstants.JUSTIFY_CONTENT_CENTER} ${sectionContainer}`}
        >
          <Col className={sectionDetailsContainer}>{leftContent}</Col>
          <Col className={sectionDetailsContainer}>{rightContent}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FullScreenSection;
