import { ReactBootstrapConstants } from 'constants/ReactBootstrapConstants';
import { RouterConstants } from 'constants/RouterConstants';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { throttle } from 'throttle-debounce';

import Logo from 'components/Logo';
import styles from 'components/Header/Header.module.css';

type HeaderProps = {
  title?: string;
};

const HEADER_BG_COLOR_EXPANDED = '#FFFFFF';
const HEADER_BG_COLOR_SHRUNK = '#6542CC';

const LOGO_CONTAINER_WIDTH = {
  shrunk: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 1,
  },
  full: {
    xs: 4,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2,
  },
};

function Header(props: HeaderProps) {
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 100 ||
            document.documentElement.scrollTop > 100)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    const throttledHandler = throttle(100, false, handler);

    window.addEventListener('scroll', throttledHandler);

    return () => {
      window.removeEventListener('scroll', throttledHandler);
    };
  }, []);

  const logoContainerWidth = isShrunk
    ? LOGO_CONTAINER_WIDTH.shrunk
    : LOGO_CONTAINER_WIDTH.full;

  return (
    <Row
      className={`${ReactBootstrapConstants.ALIGN_ITEMS_CENTER} ${styles.container} gx-0`}
      style={{
        background: isShrunk
          ? HEADER_BG_COLOR_SHRUNK
          : HEADER_BG_COLOR_EXPANDED,
      }}
    >
      <Col {...logoContainerWidth}>
        <Col
          md={isShrunk ? 10 : 12}
          lg={isShrunk ? 8 : 10}
          xl={isShrunk ? 6 : 8}
          xxl={isShrunk ? 10 : 6}
        >
          <Link
            to={RouterConstants.HOME_PATH}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Logo showXs={isShrunk} />
          </Link>
        </Col>
      </Col>
      {props.title ? (
        <Col
          xs={12 - logoContainerWidth.xs}
          sm={12 - logoContainerWidth.sm}
          md={12 - logoContainerWidth.md}
          lg={12 - logoContainerWidth.lg}
          xl={12 - logoContainerWidth.xl}
          xxl={12 - logoContainerWidth.xxl}
        >
          <HeaderTitle title={props.title} isShrunk={isShrunk} />
        </Col>
      ) : null}
    </Row>
  );
}

type HeaderTitleProps = {
  isShrunk?: boolean;
  title: string;
};

function HeaderTitle(props: HeaderTitleProps) {
  const { isShrunk, title } = props;
  const { commonTextStyle, fullHeaderTextStyle, shrunkHeaderTextStyle } =
    styles;

  return (
    <>
      {isShrunk ? (
        <h4
          className={`${commonTextStyle} ${
            isShrunk ? shrunkHeaderTextStyle : fullHeaderTextStyle
          }`}
        >
          {title}
        </h4>
      ) : (
        <h1
          className={`${commonTextStyle} ${
            isShrunk ? shrunkHeaderTextStyle : fullHeaderTextStyle
          }`}
        >
          {title}
        </h1>
      )}
    </>
  );
}

export default Header;
