export class RouterConstants {
  public static readonly HOME_PATH = '/';
  public static readonly HOME_LINK_NAME = 'Home';

  public static readonly PRIVACY_POLICY_PATH = '/privacy-policy';
  public static readonly PRIVACY_POLICY_LINK_NAME = 'Privacy Policy';

  public static readonly TERMS_AND_CONDITIONS_PATH = '/terms-and-conditions';
  public static readonly TERMS_AND_CONDITIONS_LINK_NAME =
    'Terms And Conditions';
}
