import React, { PropsWithChildren, ReactNode } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Header from 'components/Header';
import Footer from 'components/Footer';

interface LayoutProps {
  title?: string;
  hideHeader?: boolean;
  header?: ReactNode;
}

const Layout: React.FC<PropsWithChildren<LayoutProps>> = (props) => {
  return (
    <Container
      className="gx-0"
      fluid={true}
      style={{
        paddingRight: 0,
        paddingLeft: 0,
        minHeight: '100%',
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
      }}
    >
      {props.hideHeader ? null : (
        <Row
          style={{
            position: 'sticky',
            top: 0,
          }}
          className="gx-0"
        >
          <Header title={props.title} />
        </Row>
      )}
      {props.header}
      <Row
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
        className="gx-0"
      >
        {props.children}
      </Row>
      <Row className="gx-0">
        <Footer />
      </Row>
    </Container>
  );
};

export default Layout;
