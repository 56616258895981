import React from 'react';
import Stack from 'react-bootstrap/Stack';

import Layout from 'components/Layout';
import ScrollToTopOnMount from 'components/RouterComponents/ScrollToTopOnMount';
import StaticPage from 'components/StaticPage';

import styles from './PrivacyPolicy.module.css';

function PrivacyPolicy() {
  return (
    <>
      <ScrollToTopOnMount />
      <Layout title={'Privacy Policy'}>
        <StaticPage>{getPrivacyPolicy()}</StaticPage>
      </Layout>
    </>
  );
}

function getPrivacyPolicy() {
  return (
    <div>
      <Stack gap={4}>
        <div>
          <b>Last Revised: September 25, 2021</b>
        </div>
        <div>
          <b>Version: 1.0.0</b>
        </div>

        <div>
          Welcome to the Triptage Privacy Policy ("Policy"). This Policy
          explains how we collect, store, protect, and share your information
          when you use the Triptage mobile application (the “Application”), the
          Triptage website, and any other online services offered by Triptage,
          Inc. (the “Services”). The Application is a U.S.-based app, and your
          information will be sent to and used in the United States regardless
          of the country you reside in. This Policy explains how we protect your
          personal data when we transfer it overseas. While you're using the
          Application, we collect some information about you. In addition, you
          may choose to use the Application to share information with other
          members of the Triptage community, including your friends and contacts
          ("Users"). We may also need to share your information sometimes.
          Please read this Policy and our Terms and Conditions carefully. By
          using the Application or the Services, you consent to the practices
          procedures described in this Privacy Policy. If you have any questions
          about this Policy or how we use your information, please feel free to
          contact us at&nbsp;
          <a href="mailto:legal@triptage.com">legal@triptage.com</a>.
        </div>

        <div>
          <ol className="olNumbered">
            <li className="liNumbered">
              <b>Information Collected</b>
              <Stack gap={2}>
                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Personal Information</i>
                    </div>
                    <div>
                      "Personal information" is information that allows us to
                      identify you. The types of Personal Information that we
                      may collect from you may include your name, e-mail
                      address, photo, and any other details you choose to share
                      on your Application profile (“Profile”) or with us via our
                      contact form. It may also include login information and
                      friend lists for social media accounts that you connect to
                      your Profile or use to log in to the Application, such as
                      your Facebook account.
                    </div>
                    <div>
                      We may collect personal information from you on or through
                      the Application in a variety of ways, including if you
                      post a trip itinerary or trip review, submit feedback to
                      us, or use our contact form. If you contact our Customer
                      Support team, we will receive your email address, and may
                      track your IP address as well as the information you send
                      to us to help resolve your query. We will keep records of
                      our communications with you.
                    </div>
                    <div>
                      Please think critically about the information you disclose
                      about yourself. We also do not recommend that you put
                      email addresses, URLs, instant messaging details, phone
                      numbers, full names or addresses, credit card details,
                      national identity numbers, drivers' license details and
                      other sensitive information on your Profile, or provide
                      these types of information via our contact form. Please
                      remember that photographs that you post on the Application
                      may reveal information about yourself as well. Where you
                      do upload and choose to tell us sensitive information
                      about yourself, you are explicitly consenting to our
                      processing of your information and making this public to
                      other Users.
                    </div>
                    <div>
                      We also may, from time to time, receive Personal
                      Information about you from third-party sources to improve
                      the Application and our products and services, and for the
                      various purposes outlined herein.
                    </div>
                    <div>
                      Finally, with your permission, we will use your email
                      address and phone number to send you important information
                      about the Application and our other Services. If at any
                      time you do not want to receive notifications, you can
                      change your notifications settings and preferences in the
                      Application.
                    </div>
                  </Stack>
                </div>

                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Device Identifiable Information</i>
                    </div>
                    <div>
                      We may also collect information about your mobile device
                      such as the type and model, operating system (e.g. iOS or
                      Android), carrier name, mobile browser (e.g. Chrome,
                      Safari), and identifiers assigned to your device, such as
                      its iOS Identifier for Advertising (IDFA), Android
                      Advertising ID (AAID), or unique device identifier (a
                      number uniquely given to your device by your device
                      manufacturer), sometimes referred to as a mobile carrier
                      ID (“Device Identifiable Information”)
                    </div>
                  </Stack>
                </div>

                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Geolocation Information</i>
                    </div>
                    <div>
                      We may also collect your location information, such as
                      your zip code or the approximate geographic area provided
                      by your Internet service provider (ISP) or location
                      positioning information provided by the location services
                      and GPS features of your mobile device when location
                      services have been enabled.
                    </div>
                    <div>
                      If you turn these features on, when you use your mobile
                      device, we will collect information about WiFi access
                      points as well as other location information about your
                      longitude and latitude and may save your device's
                      coordinates to offer certain features to you. This
                      information helps us identify your physical location and
                      we may use it to personalize the Application and provide
                      additional services to you.
                    </div>
                    <div>
                      If you have enabled location services, but wish to turn it
                      off, you can do so by the following methods:
                      <ol className="olNumbered">
                        <li>
                          iPhone — Settings {'>'} Privacy {'>'} Location
                          Services {'>'} Triptage
                        </li>
                        <li>
                          Android — Settings {'>'} Location {'>'} Triptage {'>'}{' '}
                          Permissions {'>'} Location
                        </li>
                      </ol>
                    </div>
                  </Stack>
                </div>

                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Links</i>
                    </div>
                    <div>
                      We may keep track of how you interact with links available
                      on the Application, including third-party services and
                      clients, by redirecting clicks or through other means. We
                      may share aggregate click statistics such as how many
                      times a particular link was clicked on.
                    </div>
                  </Stack>
                </div>

                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Cookies</i>
                    </div>
                    <div>
                      We currently don’t collect cookies but reserve the right
                      to do so in the future. If and when we do collect cookies,
                      the cookies we collect enable us to learn how people
                      interact with the Application, helping us provide you with
                      a better experience in the future. Cookies store
                      information about your website visits and can recognize
                      you and your preferences each time you visit a website by
                      way of the Application.
                    </div>
                    <div>
                      If for any reason you decide that you do not want all of
                      your Application activities to be stored you may set your
                      browser and mobile settings to block cookies and local
                      storage devices, but please remember that if you do so,
                      you may not be able to access all of the features offered
                      on the Application.
                    </div>
                    <div>
                      For more information, you may review the FTC’s guide to
                      opting out of online tracking for computers and mobile
                      devices. You can also see the "Choices” section below for
                      ways to opt-out.
                    </div>
                  </Stack>
                </div>

                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Social Media</i>
                    </div>
                    <div>
                      When using the Application or when using certain
                      third-party social media services (e.g., Facebook,
                      Twitter, Instagram) (“Social Media"), you may have the
                      option to connect your information and activities with us
                      with your Social Media information and activity. If you
                      authorize such a connection, you permit us to share or
                      publish information about your activities with that Social
                      Media and its users, and to access certain of your Social
                      Media information. Information we may access about you,
                      with your consent, from a Social Media platform may
                      include, but is not limited to, your basic Social Media
                      information, your location data, your list of contacts,
                      friends or followers and certain information about your
                      activities on the Social Media. If you permit a connection
                      between a Social Media platform and our Application, we
                      (and that Social Media platform) may be able to identify
                      you and to associate information received pursuant to the
                      connection with information we already have about you. For
                      more information, please review the privacy disclosures
                      and terms of your Social Media account, which govern how
                      that account information is collected and shared with us.
                      We will never post on your Social Media accounts without
                      your express permission.
                    </div>
                  </Stack>
                </div>

                <div className={styles.liContentMargin}>
                  <Stack gap={2}>
                    <div>
                      <i>Log and Usage Data</i>
                    </div>
                    <div>
                      We keep your personal information only as long as we need
                      it for legitimate business purposes (as set out below) and
                      as permitted by applicable law.
                    </div>
                    <div>
                      In practice, all content shared by you will be retained on
                      the Application and anonymized upon deletion of your
                      Profile (following the safety retention window), unless:
                    </div>
                    <div>
                      <ul className="olNumbered">
                        <li>
                          We must keep it to comply with applicable law (for
                          instance, some “traffic data” is kept for one year to
                          comply with statutory data retention obligations);
                        </li>
                        <li>
                          We must keep it to evidence our compliance with
                          applicable law;
                        </li>
                        <li>
                          There is an outstanding issue, claim or dispute
                          requiring us to keep the relevant information until it
                          is resolved; or
                        </li>
                        <li>
                          The information must be kept for our legitimate
                          business interests, such as fraud prevention and
                          enhancing Users' safety and security. For example,
                          information may need to be kept to prevent a User who
                          was banned for unsafe behavior or security incidents
                          from creating a new Profile.
                        </li>
                      </ul>
                    </div>
                  </Stack>
                </div>
              </Stack>
            </li>

            <li className="liNumbered">
              <b>How we may use or disclose your information</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    <i>Use of Information</i>
                  </div>
                  <div>
                    The Personal Information and Device Identifiable Information
                    we collect from you may be used by us and by third-party
                    service providers, hosting providers, data management
                    companies, address list hosting companies, e-mail service
                    providers, analytics companies, distribution companies,
                    fulfillment companies and payment processors (collectively,
                    "Service Providers") for a variety of purposes, including
                    to:
                  </div>
                  <div>
                    <ul className="olNumbered">
                      <li>Offer you our Services and features;</li>
                      <li>
                        Contact you with information about the Application
                        (e.g., updates and new offerings);
                      </li>
                      <li>
                        Personalize the Application and the content we deliver
                        to you;
                      </li>
                      <li>
                        Conduct research and analytics about how you use and
                        interact with the Application;
                      </li>
                      <li>
                        Improve and develop our Application, website, and other
                        Services;
                      </li>
                      <li>
                        Resolve disputes between you and us or you and other
                        Users; and
                      </li>
                      <li>
                        Investigate fraud, protect our legal rights, and to
                        enforce our Terms & Conditions.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <i>Other Disclosures.&nbsp;&nbsp;</i> We may also share
                    Personal Information and Device Identifiable Information
                    under the following circumstances: (1) if required by law,
                    such as by a court order, statute, regulation or rule,
                    through legal process, or for law enforcement purposes; (2)
                    in the event of a corporate transaction involving our
                    business, such as the sale of that business or all or most
                    of Triptage Inc.’s assets or equity to another company; (3)
                    in the event of a bankruptcy, insolvency, reorganization,
                    receivership or assignment for the benefit of our creditors;
                    (4) if we determine it necessary in connection with an
                    investigation or in order to protect our legal rights; or
                    (5) in response to requests from federal, state, local or
                    foreign law and civil enforcement agencies, such as a search
                    warrant, subpoena or court order.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>What others may see about you</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    You understand that your Profile and other information you
                    make available via the Application may be viewed and shared
                    by other Users of the Application. When using the
                    Application, you should assume that anything you post or
                    submit on the Application may be publicly viewable and
                    accessible by Users of the Application.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Third-party advertising</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    We may allow other companies that are presenting advertising
                    content or other services on our Application or website to
                    set and access cookies and other tracking technologies on
                    your mobile device or computer. These companies may collect
                    information about your visits to the Application or website
                    in order to measure and assess the effectiveness of online
                    advertising and to provide and better target advertisements
                    about products and services of interest to you. While the
                    information collected by these third parties from you while
                    you are using our Application or visiting our website is not
                    personally identifiable, some of these third parties may
                    also combine such non-personal information with other
                    information they have collected from various other sources,
                    usage from other sites and application. Such other
                    information may include Personal Information, as well as
                    demographic and behavioral information. Those companies’ use
                    of cookies, and the information collected, are governed by
                    those companies’ privacy policies, not ours.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Choices</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    You have various choices with respect to certain of our uses
                    of the Personal Information we collect from you on or
                    through the Application and other Services.
                  </div>
                  <div>
                    <ul>
                      <li>
                        <b>
                          <u>Correct or view your personal information:</u>
                        </b>{' '}
                        You may access your Profile to correct or view certain
                        personal information of yours in our possession and
                        which is associated with your profile.
                      </li>
                      <li>
                        <b>
                          <u>Location settings:</u>
                        </b>{' '}
                        You can prevent your mobile device from sharing your
                        location data by adjusting the permissions on your
                        mobile device or within the Services.
                      </li>
                      <li>
                        <b>
                          <u>Marketing emails:</u>
                        </b>{' '}
                        You may opt-out of receiving marketing emails from us by
                        clicking the “unsubscribe” link provided with each
                        email. Please note that we will continue to send you
                        emails necessary to the Services or any assistance you
                        request.
                      </li>
                      <li>
                        <b>
                          <u>Marketing text messages:</u>
                        </b>{' '}
                        You may opt-out of receiving marketing text messages
                        from us by responding “STOP” to any of our text
                        messages.
                      </li>
                      <li>
                        <b>
                          <u>Push notifications:</u>
                        </b>{' '}
                        If you have enabled push notifications, you may disable
                        these at any time by updating your device settings.
                      </li>
                      <li>
                        <b>
                          <u>Uninstall the Application:</u>
                        </b>{' '}
                        You can stop all further collection of your personal
                        information by the Services by uninstalling the
                        Application.
                      </li>
                    </ul>
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Security practices</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    We use commercially reasonable security efforts to protect
                    the data in our possession against loss, misuse and
                    unauthorized access, or disclosure. However, no method of
                    transmission or storage of data is 100% secure and we cannot
                    guarantee that unauthorized access, hacking, data loss or
                    other breaches will never occur. We will not be responsible
                    for any damage that results from a security breach of data
                    or the unauthorized access to or use of information, whether
                    Personal Information or Device Identifiable Information.
                    Here are some tips to help keep your data secure:
                  </div>
                  <div>
                    <ol>
                      <li>
                        Please make sure you log out of the Application after
                        use.
                      </li>
                      <li>
                        Please don't share your Social Media passwords with
                        anyone else.
                      </li>
                      <li>Change your Social Media passwords periodically.</li>
                    </ol>
                  </div>
                  <div>
                    If you ever think someone has had access to your Social
                    Media password, please report it to the relevant Social
                    Media platform and change your password immediately. We
                    cannot guarantee the security of your personal data while it
                    is being transmitted to our site and any transmission is at
                    your own risk.
                  </div>
                  <div>
                    <b>
                      We expressly disclaim any representation or warranty,
                      whether express or implied, with respect to any breaches
                      of security, damage to your device, or any loss or
                      unauthorized use of your registration information or other
                      data.
                    </b>
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>
                Accessing, reviewing, updating or correcting your personal
                information
              </b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    If you want to access, review, update or correct
                    inaccuracies in your Personal Information, you can revise
                    your preferences by emailing us at&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>
                    or writing to us at the address set forth below. Attn:
                    Privacy Administrator. Upon receiving your communication, we
                    will take steps to update or correct such information in our
                    possession, or to remove you from our mailing list. Your
                    preferences include, for example, your profile information,
                    as well as any payment and account information (if
                    applicable), as well as whether you want to receive
                    communications from us. If you want to close your account
                    with us and have us delete the Personal Information in it
                    (except for information that we keep for record-keeping
                    purposes), you should contact our Privacy Administrator. We
                    may request certain Personal Information for the purposes of
                    verifying the identity of the individual seeking access to
                    their personal information records.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Third party accounts</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    Remember that when you register with a third party (for
                    example, a Social Media account that you use to log into the
                    Application), you are also giving them personal information,
                    so we recommend that you read their privacy policies as
                    Triptage does not control how they use their information.
                  </div>
                  <div>
                    If you have registered on one of these third-party
                    applications, such as Facebook, we may connect your Profile
                    with your profile on such application. If you have created a
                    Profile through a third-party application and you do not
                    want to link your Profile to your profile on the third-party
                    application, Triptage cannot amend these settings. Please
                    visit the application settings on your third-party profile
                    and follow the instructions to remove the Triptage access
                    permissions.
                  </div>
                  <div>
                    We only use the APIs, OAuth Tokens, Widgets, or other means
                    provided by the applicable Social Media account to integrate
                    your Social Media account with our Application. As such, we
                    only receive the limited information that each Social Media
                    account permits to be transferred.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Links to other websites and services</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    The Application may contain links to other websites and
                    online services. When you link to such other sites and
                    services (including by means of clicking on hyperlinks,
                    logos, widgets, banners or advertisements), you become
                    subject to their applicable terms of use and privacy
                    policies. Please note that we are not responsible for the
                    privacy practices of third-party websites. We encourage you
                    to be aware of this when you click on a link and leave the
                    Application, and to read the privacy policies of every
                    website that collects Personal Information from you.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Additional information</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    The Application is operated and maintained, and the Services
                    are provided from within the U.S. We make no assurances or
                    representations of any kind that the Application is suitable
                    for use outside the U.S. or in the country in which you
                    reside. The information we collect is kept on servers in the
                    U.S. and may be transferred to servers in other countries,
                    subject to the terms of this Privacy Policy. We retain the
                    Personal Information that we collect for so long as we
                    continue to have a business purpose for it.
                  </div>
                  <div>
                    By accessing the Application, you consent to the processing
                    of your Personal Information as provided in this Privacy
                    Policy. You may withdraw that consent by contacting us
                    at&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>.
                    You may be required to provide certain Personal Information
                    in order to take advantage of certain features available
                    through the Application.
                  </div>
                  <div>
                    If you wish to object to or raise a complaint on how we have
                    handled your Personal Information, you may contact us
                    at&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>.
                  </div>
                  <div>
                    If you are not satisfied with our response or believe our
                    processing of your Personal Information is not in accordance
                    with law, you may register a complaint with a governmental
                    authority.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Local User rights</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div className={styles.singleIndent}>
                    <i>Your California privacy rights</i>
                  </div>
                  <div>
                    The California Consumer Privacy Act (CCPA) grants certain
                    rights to California residents. If our processing of your
                    personal information is subject to the CCPA, you are
                    entitled to the following rights:
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to access.</u> You have the right to request what
                    personal information we have collected, used, disclosed, and
                    sold about you within the preceding 12 months. You may only
                    make a request for access twice within a 12-month period.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to deletion.</u> You have the right to request the
                    deletion of your personal information that we collect or
                    maintain, subject to certain exceptions.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to opt-out.</u> You have the right to opt-out of
                    the sale of your personal information to third parties.
                    Triptage does not have actual knowledge that it sells
                    personal information of minors under the age of 16 years.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to non-discrimination.</u> You have the right to
                    not receive discriminatory treatment if and when you
                    exercise your rights to access, delete, or opt-out under the
                    CCPA.
                  </div>
                  <div>
                    To exercise your right to access or delete your personal
                    information, you may submit a request to us via email
                    at&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>.
                    You must provide us with sufficient information that allows
                    us to reasonably verify you are the person about whom we
                    collected the personal information and describe your request
                    with sufficient detail to allow us to properly evaluate and
                    respond to it. If we are not able to verify your identity
                    for access and deletion requests with the information
                    provided, we may ask you for additional pieces of
                    information.
                  </div>
                  <div>
                    Only you, or a person registered with the California
                    Secretary of State that you authorize to act on your behalf,
                    may make a request related to your personal information. If
                    you are an authorized agent making a request on behalf of
                    another individual, you must provide us with signed
                    documentation that you are authorized to act on behalf of
                    that individual.
                  </div>

                  <div className={styles.singleIndent}>
                    <i>Rights of Nevada Residents</i>
                  </div>
                  <div>
                    If you are a consumer in the State of Nevada, you may
                    request to opt-out of the current or future sale of your
                    personal information. We do not currently sell any of your
                    personal information under Nevada law nor do we plan to do
                    so in the future. However, you can submit a request to
                    opt-out of future sales by contacting us at&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>.
                    Please include “Opt-Out Request Under Nevada Law” in the
                    subject line of your message.
                  </div>

                  <div className={styles.singleIndent}>
                    <i>Your UK and EU rights</i>
                  </div>
                  <div>
                    Triptage Inc. is exclusively based in the United States. If
                    you are based in the UK or the EU, you have the following
                    rights, but please note that as an exclusively U.S.-based
                    company, Triptage Inc. disclaims any and all liability with
                    respect to privacy rights under UK and/or EU law or anywhere
                    in the world outside of the United States.
                  </div>
                  <div>
                    Under UK and EU law, UK and EU users have the right to lodge
                    a complaint with data protection regulators, and the
                    Information Commissioners’ Office (ICO) is the UK’s lead
                    regulator. You can find out how to raise a concern with the
                    ICO by visiting their website at www.ico.org.uk. If you’re
                    within the EU, you may also get in touch with your local
                    Data Protection Regulator who may liaise with the ICO on
                    your behalf.
                  </div>
                  <div>
                    You have a number of rights under European Data Protection
                    law if you are an EU or UK citizen.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to be informed:</u> what personal data an
                    organization is processing and why (this notice).
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right of access:</u> you can request a copy of your data.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right of rectification:</u> if the data held is
                    inaccurate, you have the right to have it corrected.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to erasure:</u> you have the right to have your
                    data deleted in certain circumstances.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to restrict processing:</u> in limited
                    circumstances, you have the right to request that processing
                    is stopped but the data retained.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to data portability:</u> you can request a copy of
                    your data in a machine-readable form that can be transferred
                    to another provider.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>Right to object:</u> in certain circumstances (including
                    where data is processed on the basis of legitimate interests
                    or for the purposes of marketing) you may object to that
                    processing.
                  </div>
                  <div className={styles.singleIndent}>
                    <u>
                      Rights related to automated decision making including
                      profiling:
                    </u>{' '}
                    there are several rights in this area where processing
                    carried out on a solely automated basis results in a
                    decision which has legal or significant effects for the
                    individual. In these circumstances your rights include the
                    right to ensure that there is human intervention in the
                    decision-making process.
                  </div>
                  <div>
                    As a resident of the UK or EU, you can exercise these rights
                    by emailing us at&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Changes to this Privacy Policy</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    You should review this Privacy Policy periodically as we may
                    modify it from time to time, and such changes will be
                    effective upon our posting them to the Application and our
                    website. If we make a change to this policy that, in our
                    sole discretion, is material, we will notify you, for
                    example, via an email to the email associated with your
                    Profile or by posting a notice within Triptage. Where
                    consistent with applicable law, your continued use of the
                    Application after we have changed the Privacy Policy
                    signifies your acceptance of the revised terms.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Special rules for children</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    The Application is directed to general audiences and we do
                    not knowingly collect Personal Information from children
                    under 18. If you are less than 18 years old, we request that
                    you do not submit information to us. If we become aware that
                    a child, minor or anyone under the age of 18 has created a
                    Profile and provided us with personal information, we will
                    take steps to terminate that person's registration and
                    delete their Profile from Triptage. If we do delete a
                    Profile because you violated this rule, we may retain your
                    email and IP address to ensure that you do not try to get
                    around our rules by creating a new Profile.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>About us</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    Your access to the Application, as well as this Privacy
                    Policy are governed and interpreted by the laws of the State
                    of Washington, other than such laws, rules, regulations and
                    case law that would result in the application of the laws of
                    a jurisdiction other than the State of Washington. By using
                    the Application, you are consenting to the exclusive
                    jurisdiction of the courts of the United States and the
                    State of Washington. You agree that such courts shall have
                    in personam jurisdiction and venue and waive any objection
                    based on inconvenient forum. You agree that you will not
                    file or participate in a class action against us. In the
                    event there is a discrepancy between this English language
                    version and any translated copies of the Policy, the English
                    version shall prevail.
                  </div>
                </Stack>
              </div>
            </li>

            <li className="liNumbered">
              <b>Contact us</b>
              <div className={styles.liContentMargin}>
                <Stack gap={2}>
                  <div>
                    If you have questions or concerns about this Privacy Policy,
                    you can contact us at:
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div>
                      Triptage Inc.
                      <br />
                      117 E. Louisa Street #361
                      <br />
                      Seattle, WA 98102
                      <br />
                      USA
                      <br />
                      Email:&nbsp;
                      <a href="mailto:legal@triptage.com">legal@triptage.com</a>
                    </div>
                  </div>
                </Stack>
              </div>
            </li>
          </ol>
        </div>
      </Stack>
    </div>
  );
}

export default PrivacyPolicy;
