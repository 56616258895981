import React from 'react';
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';

import Layout from 'components/Layout';
import ScrollToTopOnMount from 'components/RouterComponents/ScrollToTopOnMount';
import StaticPage from 'components/StaticPage';

import { RouterConstants } from 'constants/RouterConstants';

function TermsAndConditions() {
  return (
    <>
      <ScrollToTopOnMount />
      <Layout title={'Terms and Conditions'}>
        <StaticPage>{getTermsAndConditions()}</StaticPage>
      </Layout>
    </>
  );
}

function getTermsAndConditions() {
  return (
    <div>
      <Stack gap={4}>
        <div>
          <b>Last Revised: September 25, 2021</b>
        </div>
        <div>
          <b>Version: 1.0.0</b>
        </div>

        <div>
          These Terms and Conditions (this <b>“Agreement”</b>) are by and
          between Triptage Inc., a Delaware corporation (<b>“Company”</b>,{' '}
          <b>“us”</b>, <b>“our”</b>, and <b>“we”</b>), and all users of
          Company’s mobile application (the <b>“Application”</b>), as well as
          any other Company website, media channel or mobile application related
          thereto (all of the foregoing, collectively, the <b>“Services”</b>).
        </div>

        <div>
          Certain features of the Services may be subject to additional
          guidelines, terms, or rules, which will be posted in connection with
          such features, including, but not limited to, our Privacy Policy
          located at{' '}
          <Link to={RouterConstants.PRIVACY_POLICY_PATH}>Privacy Policy</Link>.
          All such additional terms, guidelines, and rules are incorporated by
          reference into this Agreement.
        </div>

        <div>
          THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS
          THAT GOVERN YOUR USE OF THE SERVICES. BY ACCESSING OR USING THE
          SERVICES, YOU ARE ACCEPTING THIS AGREEMENT (ON BEHALF OF YOURSELF OR
          THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU
          HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT
          (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU
          REPRESENT AND WARRANT THAT (A) YOU ARE NOT LOCATED IN A COUNTRY THAT
          IS SUBJECT TO ANY U.S. GOVERNMENT EMBARGO, OR THAT HAS BEEN DESIGNATED
          BY THE U.S. GOVERNMENT AS A "TERRORIST SUPPORTING" COUNTRY, AND (B)
          YOU ARE NOT LISTED ON ANY U.S. GOVERNMENT LIST OF PROHIBITED OR
          RESTRICTED PARTIES. YOU MAY NOT ACCESS OR USE THE SERVICES OR ACCEPT
          THIS AGREEMENT IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT
          AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS
          AND/OR USE THE SERVICES.
        </div>

        <ol className="olNumbered">
          <li className="liNumbered">
            <b>PROFILES AND LOGIN</b>
            <ol className="olNumbered">
              <li className="liNumbered">
                <b>Profile Creation.&nbsp;&nbsp;</b> In order to use certain
                features of the Services, you may need set up a profile (
                <b>“Profile”</b>) and provide certain information about
                yourself. If you create a Profile, you represent and warrant
                that: (a) all required information you submit to Company is
                truthful and accurate; and (b) you will maintain the accuracy of
                such information. Registration data and certain other
                information about you are governed by our Privacy Policy.
              </li>
              <li className="liNumbered">
                <b>Login.&nbsp;&nbsp;</b> In order to access the Services, you
                may need to log in using a third-party service (Apple ID,
                Facebook profile, etc.) (each, a <b>“Third Party Service”</b>
                ). Your use of a Third Party Service to access our Services is
                subject to the terms of use and policies of such Third Party
                Service. You are responsible for maintaining the confidentiality
                of your login information for any Third Party Service you
                utilize.
              </li>
              <li className="liNumbered">
                <b>Responsibilities.&nbsp;&nbsp;</b> You are responsible for
                paying all costs related to the transmission of data required to
                use the Services (downloading, installing, launching, and
                using). Company shall not be liable for fees charged by third
                parties. If you create a Profile: you are fully responsible for
                all activities that occur under your Profile; and you agree to
                immediately notify Company of any actual or suspected
                unauthorized use of your Profile or any other breach of
                security. Company cannot and will not be liable for any loss or
                damage arising from your failure to comply with the above
                requirements.
              </li>
            </ol>
          </li>

          <li className="liNumbered">
            <b>ACCESS TO THE SERVICES </b>
            <ol className="olNumbered">
              <li className="liNumbered">
                <b>License.&nbsp;&nbsp;</b> Subject to this Agreement, Company
                grants you a non-transferable, non-exclusive, revocable, limited
                license to use and access the Services solely for your own
                personal use.
              </li>
              <li className="liNumbered">
                <b>Certain Restrictions.&nbsp;&nbsp;</b> The rights granted to
                you in this Agreement are subject to the following restrictions:
                (a) you shall not license, sell, rent, lease, transfer, assign,
                or host the Services, whether in whole or in part, or any
                content displayed on the Services; (b) you shall not modify,
                make derivative works of, disassemble, reverse compile or
                reverse engineer any part of the Services; (c) you shall not
                access the Services in order to build a similar or competitive
                product, or service; and (d) except as expressly stated herein,
                no part of the Services may be copied, reproduced, distributed,
                republished, downloaded, displayed, posted or transmitted in any
                form or by any means. Unless otherwise indicated, any future
                release, update, or other addition to functionality of the
                Services shall be subject to this Agreement. All copyright and
                other proprietary notices on the Services (or on any content
                displayed on the Services) must be retained on all copies
                thereof.
              </li>
              <li className="liNumbered">
                <b>Modification.&nbsp;&nbsp;</b> Company reserves the right, at
                any time, to modify, suspend, or discontinue the Services (in
                whole or in part) with or without notice to you. You agree that
                Company will not be liable to you or to any third party for any
                modification, suspension, or discontinuation of the Services or
                any part thereof.
              </li>
              <li className="liNumbered">
                <b>Support or Maintenance.&nbsp;&nbsp;</b> You acknowledge and
                agree that Company may, but is not obligated to, provide you
                with support or maintenance in connection with the Services.
              </li>
              <li className="liNumbered">
                <b>Ownership.&nbsp;&nbsp;</b> Excluding any User Content that
                you may provide (defined below), you acknowledge that all the
                intellectual property rights, including copyrights, patents,
                trademarks, and trade secrets in the Services and its content
                are owned by Company or Company’s suppliers. Neither this
                Agreement, nor your access to the Services, transfers to you or
                any third party any rights, title or interest in or to such
                intellectual property rights, except for the limited access
                rights expressly set forth in Section 2.1. Company and its
                suppliers reserve all rights not expressly granted in this
                Agreement. There are no implied licenses granted under this
                Agreement.
              </li>
              <li className="liNumbered">
                <b>Open Source.&nbsp;&nbsp;</b> Certain items of independent,
                third-party code may be included in the Services that are
                subject to the GNU General Public License (“GPL”) or other
                open-source licenses (“Open-Source Software”). The Open-Source
                Software is licensed under the terms of the license that
                accompanies such Open-Source Software. Nothing in this Agreement
                limits your rights under, or grants you rights that supersede,
                the terms and conditions of any applicable end-user license for
                such Open-Source Software. In particular, nothing in this
                Agreement restricts your right to copy, modify and distribute
                such Open-Source Software that is subject to the terms of the
                GPL.
              </li>
            </ol>
          </li>

          <li className="liNumbered">
            <b>USER CONTENT</b>
            <ol className="olNumbered">
              <li className="liNumbered">
                <b>User Content.&nbsp;&nbsp;</b> <b>“User Content”</b> means any
                and all information, data and content that a user submits to, or
                uses with, the Services, including, but not limited to, Profile
                information, bucket lists, trip reviews, photos, suggested
                itineraries, and feedback provided through our application,
                website, or other means. You are solely responsible for your
                User Content. You assume all risks associated with use of your
                User Content, including any reliance on its accuracy,
                completeness or usefulness by others, or any disclosure of your
                User Content that personally identifies you or any third party.
                You hereby represent and warrant that your User Content does not
                violate our Acceptable Use Policy (defined in Section 3.3). You
                may not represent or imply to others that your User Content is
                in any way provided, sponsored or endorsed by Company. Because
                you alone are responsible for your User Content, you may expose
                yourself to liability if, for example, your User Content
                violates the Acceptable Use Policy. Company may, but is not
                obligated to, backup any User Content, and your User Content may
                be deleted at any time without prior notice. You are solely
                responsible for creating and maintaining your own backup copies
                of your User Content if you desire.
              </li>
              <li className="liNumbered">
                <b>License.&nbsp;&nbsp;</b> You hereby grant (and you represent
                and warrant that you have the right to grant) to Company an
                irrevocable, nonexclusive, royalty-free and fully paid,
                worldwide license to reproduce, distribute, publicly display and
                perform, prepare derivative works of, incorporate into other
                works, and otherwise use and exploit your User Content, and to
                grant sublicenses of the foregoing rights, for the purposes of
                including your User Content in the Services. You hereby
                irrevocably waive (and agree to cause to be waived) any claims
                and assertions of moral rights or attribution with respect to
                your User Content.
              </li>
              <li className="liNumbered">
                <b>Acceptable Use Policy.&nbsp;&nbsp;</b> The following terms
                constitute our <b>“Acceptable Use Policy”</b>:
                <ol className="olNumbered">
                  <li className="liNumbered">
                    You agree not to use the Services to collect, upload,
                    transmit, display, or distribute any User Content (i) that
                    violates any third-party right, including any copyright,
                    trademark, patent, trade secret, moral right, privacy right,
                    right of publicity, or any other intellectual property or
                    proprietary right; (ii) that is unlawful, harassing,
                    abusive, tortious, threatening, harmful, invasive of
                    another’s privacy, vulgar, defamatory, false, intentionally
                    misleading, trade libelous, pornographic, obscene, patently
                    offensive, promotes racism, bigotry, hatred, or physical
                    harm of any kind against any group or individual or is
                    otherwise objectionable; (iii) that is harmful to minors in
                    any way; or (iv) that is in violation of any law,
                    regulation, or obligations or restrictions imposed by any
                    third party.
                  </li>
                  <li className="liNumbered">
                    In addition, you agree not to: (i) upload, transmit, or
                    distribute to or through the Services any computer viruses,
                    worms, or any software intended to damage or alter a
                    computer system or data; (ii) send through the Services
                    unsolicited or unauthorized advertising, promotional
                    materials, junk mail, spam, chain letters, pyramid schemes,
                    or any other form of duplicative or unsolicited messages,
                    whether commercial or otherwise; (iii) use the Services to
                    harvest, collect, gather or assemble information or data
                    regarding other users, including e-mail addresses, without
                    their consent; (iv) interfere with, disrupt, or create an
                    undue burden on servers or networks connected to the
                    Services, or violate the regulations, policies or procedures
                    of such networks; (v) attempt to gain unauthorized access to
                    the Services (or to other computer systems or networks
                    connected to or used together with the Services), whether
                    through password mining or any other means; (vi) harass or
                    interfere with any other user’s use and enjoyment of the
                    Services; (vi) use software or automated agents or scripts
                    to produce multiple accounts on the Services, or to generate
                    automated searches, requests, or queries to (or to strip,
                    scrape, or mine data from) the Services (provided, however,
                    that we may grant to the operators of public search engines
                    permission to use spiders to copy materials from the
                    Services for the purpose of and to the extent necessary for
                    creating publicly available searchable indices of the
                    materials, subject to parameters we may set from time to
                    time), or (vii) violate any applicable law, regulation or
                    policy.
                  </li>
                </ol>
              </li>
              <li className="liNumbered">
                <b>Enforcement.&nbsp;&nbsp;</b> We reserve the right (but have
                no obligation) to review any User Content, and to investigate
                and/or take appropriate action against you in our sole
                discretion if you violate the Acceptable Use Policy or any other
                provision of this Agreement or otherwise create liability for us
                or any other person. Such action may include removing or
                modifying your User Content, suspending or terminating your
                Profile in accordance with Section 8, and/or reporting you to
                law enforcement authorities.
              </li>
              <li className="liNumbered">
                <b>Feedback.&nbsp;&nbsp;</b> If you provide Company with any
                feedback or suggestions regarding the Services (
                <b>“Feedback”</b>), you hereby assign to Company all rights in
                such Feedback and agree that Company shall have the right to use
                and fully exploit such Feedback and related information in any
                manner it deems appropriate. Company will treat any Feedback you
                provide to Company as non-confidential and non-proprietary. You
                agree that you will not submit to Company any information or
                ideas that you consider to be confidential or proprietary.
              </li>
            </ol>
          </li>

          <li className="liNumbered">
            <b>INDEMNIFICATION.&nbsp;&nbsp;</b> You agree to indemnify and hold
            Company (and its officers, directors, employees, affiliates and
            agents) harmless, including costs and attorneys’ fees, from any
            claim or demand made by any third party due to or arising out of (a)
            your use of the Services, (b) your violation of this Agreement, (c)
            your violation of applicable laws or regulations or (d) your User
            Content. Company reserves the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us, and you agree to cooperate with our
            defense of these claims. You agree not to settle any matter without
            the prior written consent of Company. Company will use reasonable
            efforts to notify you of any such claim, action or proceeding upon
            becoming aware of it.
          </li>

          <li className="liNumbered">
            <b>THIRD-PARTY LINKS & ADS; OTHER USERS</b>
            <ol className="olNumbered">
              <li className="liNumbered">
                <b>Third-Party Links & Ads.&nbsp;&nbsp;</b> The Services may
                contain links to third-party websites, content and services,
                and/or display advertisements for third parties (collectively,{' '}
                <b>“Third-Party Links & Ads”</b>). Such Third-Party Links & Ads
                are not under the control of Company, and Company is not
                responsible for any Third-Party Links & Ads. Company does not
                review, approve, monitor, endorse, warrant, or make any
                representations with respect to Third-Party Links & Ads. You use
                all Third-Party Links & Ads at your own risk, and should apply a
                suitable level of caution and discretion in doing so. When you
                click on any of the Third-Party Links & Ads, the applicable
                third party’s terms and policies apply, including the third
                party’s privacy and data gathering practices. You should make
                whatever investigation you feel necessary or appropriate before
                proceeding with any transaction in connection with such
                Third-Party Links & Ads.
              </li>
              <li className="liNumbered">
                <b>Other Users.&nbsp;&nbsp;</b> Each user of the Services is
                solely responsible for any and all of its own User Content. You
                acknowledge and agree that we are not responsible for any User
                Content, whether provided by you or by others. We make no
                guarantees regarding the accuracy, currency, suitability, or
                quality of any User Content. Your interactions with other users
                are solely between you and such users. You agree that Company
                will not be responsible for any loss or damage incurred as the
                result of any such interactions. If there is a dispute between
                you and any other user, we are under no obligation to become
                involved.
              </li>
              <li className="liNumbered">
                <b>Release.&nbsp;&nbsp;</b> You hereby release and forever
                discharge Company (and our officers, employees, agents,
                successors, and assigns) from, and hereby waive and relinquish,
                each and every past, present and future dispute, claim,
                controversy, demand, right, obligation, liability, action and
                cause of action of every kind and nature (including personal
                injuries, death, and property damage), that has arisen or arises
                directly or indirectly out of, or that relates directly or
                indirectly to, the Services (including any interactions with, or
                act or omission of, other users or any Third-Party Links & Ads).
                IF YOU ARE A RESIDENT OF THE STATE OF CALIFORNIA, USA, YOU
                HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION
                WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT
                EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
                EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS
                OR HER SETTLEMENT WITH THE DEBTOR.”
              </li>
            </ol>
          </li>

          <li className="liNumbered">
            <b>DISCLAIMERS</b>
            <Stack gap={4}>
              <div>
                THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE”
                BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY
                AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
                ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS)
                MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS,
                WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
                ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES
                OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE
                LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SERVICES, ALL
                SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM
                THE DATE OF FIRST USE.
              </div>
              <div>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME
                JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
                WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
              </div>
            </Stack>
          </li>

          <li className="liNumbered">
            <b>LIMITATION ON LIABILITY</b>
            <Stack gap={4}>
              <div>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY
                FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
                SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING
                TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE
                SERVICES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN
                DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM OR BUSINESS, OR LOSS OF
                DATA RESULTING THEREFROM.
              </div>
              <div>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING
                TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT AND THE
                SERVICES (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
                THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY
                US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL
                NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE
                NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
                AGREEMENT.
              </div>
              <div>
                SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
                LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </div>
            </Stack>
          </li>

          <li className="liNumbered">
            <b>TERM AND TERMINATION.&nbsp;&nbsp;</b>
            Subject to this Section, this Agreement will remain in full force
            and effect while you use the Services. You may stop using the
            Services at any time. We may suspend or terminate your rights to use
            the Services (including your Profile) at any time for any reason at
            our sole discretion, including for any use of the Services in
            violation of this Agreement. Upon termination of your rights under
            this Agreement, your Profile and right to access and use the
            Services will terminate immediately. You understand that any
            termination of your Profile may involve permanent deletion of your
            User Content associated with your Profile. Company will not have any
            liability whatsoever to you for any termination of your rights under
            this Agreement, including for termination of your Profile or
            deletion of your User Content. Even after your rights under this
            Agreement are terminated, the following provisions of this Agreement
            will remain in effect: Sections 2.2 through 2.7, and Sections 3
            through 10.
          </li>

          <li className="liNumbered">
            <b>COPYRIGHT POLICY.</b>
            <Stack gap={4}>
              <div>
                If you believe that another user is, through the use of the
                Services, unlawfully infringing the copyright(s) in a work, and
                wish to have the allegedly infringing material removed, the
                following information in the form of a written notification
                (pursuant to 17 U.S.C. § 512(c)) must be provided to our
                designated Copyright Agent, who can be reached at the address
                set forth below:
              </div>
              <ol className="olNumbered">
                <li className="liNumbered">
                  your physical or electronic signature;
                </li>
                <li className="liNumbered">
                  identification of the copyrighted work(s) that you claim to
                  have been infringed;
                </li>
                <li className="liNumbered">
                  identification of the material on the Services that you claim
                  is infringing and that you request us to remove;
                </li>
                <li className="liNumbered">
                  sufficient information to permit us to locate such material;
                </li>
                <li className="liNumbered">
                  your address, telephone number, and e-mail address;
                </li>
                <li className="liNumbered">
                  a statement that you have a good faith belief that use of the
                  objectionable material is not authorized by the copyright
                  owner, its agent, or under the law; and
                </li>
                <li className="liNumbered">
                  a statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are either
                  the owner of the copyright that has allegedly been infringed
                  or that you are authorized to act on behalf of the copyright
                  owner.
                </li>
              </ol>
              <div>
                Please note that, pursuant to 17 U.S.C. § 512(f), any
                misrepresentation of material fact (falsities) in a written
                notification automatically subjects the complaining party to
                liability for any damages, costs and attorney’s fees incurred by
                us in connection with the written notification and allegation of
                copyright infringement.
              </div>
            </Stack>
          </li>

          <li className="liNumbered">
            <b>GENERAL</b>
            <ol className="olNumbered">
              <li className="liNumbered">
                <b>Changes.&nbsp;&nbsp;</b> This Agreement is subject to
                occasional revision, and if we make any substantial changes, we
                may notify you by sending you an e-mail to the last e-mail
                address you provided to us (if any), and/or by prominently
                posting notice of the changes on the Application. If you have
                provided us with an e-mail address, you are responsible for
                ensuring that it is your most current e-mail address. In the
                event that the last e-mail address that you have provided us is
                not valid, or for any reason is not capable of delivering to you
                the notice described above, our dispatch of the e-mail
                containing such notice will nonetheless constitute effective
                notice of the changes described in the notice. Any changes to
                this Agreement will be effective upon notice to you in the
                manner described above. Continued use of the Services following
                notice of such changes shall indicate your acknowledgement of
                such changes and agreement to be bound by the terms and
                conditions of such changes.
              </li>
              <li className="liNumbered">
                <b>Export.&nbsp;&nbsp;</b> The Services may be subject to U.S.
                export control laws and may be subject to export or import
                regulations in other countries. You agree not to export,
                reexport, or transfer, directly or indirectly, any U.S.
                technical data acquired from Company, or any products utilizing
                such data, in violation of the United States export laws or
                regulations.
              </li>
              <li className="liNumbered">
                <b>Disclosures.&nbsp;&nbsp;</b> Company is located at the
                address set forth below. If you are a resident of the state of
                California, USA, you may report complaints to the Complaint
                Assistance Unit of the Division of Consumer Services of the
                California Department of Consumer Affairs in writing at 1625
                North Market Blvd., Suite N 112, Sacramento, California 95834 or
                by telephone at (800) 952-5210.
              </li>
              <li className="liNumbered">
                <b>Electronic Communications.&nbsp;&nbsp;</b> The communications
                between you and Company use electronic means, whether you use
                the Services, reach out to us via our contact form, or send us
                emails, or whether Company posts notices on the Services or
                communicates with you via email. For contractual purposes, you
                (a) consent to receive communications from Company in an
                electronic form; and (b) agree that all terms and conditions,
                agreements, notices, disclosures, and other communications that
                Company provides to you electronically satisfy any legal
                requirement that such communications would satisfy if it were be
                in a hardcopy writing. The foregoing does not affect your
                non-waivable rights.
              </li>
              <li className="liNumbered">
                <b>Your Privacy.&nbsp;&nbsp;</b> At Company, we respect the
                privacy of our users. For details, please see our Privacy
                Policy, which may be accessed here,{' '}
                <Link to={RouterConstants.PRIVACY_POLICY_PATH}>
                  Privacy Policy
                </Link>
                . By using the Services, you consent to our collection and use
                of personal data as outlined therein.
              </li>
              <li className="liNumbered">
                <b>Miscellaneous.&nbsp;&nbsp;</b> This Agreement shall be
                governed by the laws of the State of Washington, USA, without
                giving effect to any conflict of laws principles that may
                provide the application of the law of another jurisdiction. The
                parties agree the state and federal courts sitting in Seattle,
                Washington, USA shall have exclusive jurisdiction over any
                dispute arising out of or relating to this Agreement and the
                parties waive any objection based on venue or inconvenient
                forum. In any action or suit to enforce any right or remedy
                under this Agreement or to interpret any provisions of this
                Agreement, the prevailing party shall be entitled to recover
                reasonable attorneys' fees and costs, including expert witness
                fees. This Agreement constitutes the entire agreement between
                you and us regarding the use of the Services. The English
                language version of this Agreement shall sole governing version
                and any translation into another language shall be of no force
                or effect. Our failure to exercise or enforce any right or
                provision of this Agreement shall not operate as a waiver of
                such right or provision. The section titles in this Agreement
                are for convenience only and have no legal or contractual
                effect. The word “including” means “including without
                limitation”. If any provision of this Agreement is, for any
                reason, held to be invalid or unenforceable, the other
                provisions of this Agreement will be unimpaired and the invalid
                or unenforceable provision will be deemed modified so that it is
                valid and enforceable to the maximum extent permitted by law.
                Your relationship to Company is that of an independent
                contractor, and neither party is an agent or partner of the
                other. This Agreement, and your rights and obligations herein,
                may not be assigned, subcontracted, delegated, or otherwise
                transferred by you without Company’s prior written consent, and
                any attempted assignment, subcontract, delegation, or transfer
                in violation of the foregoing will be null and void. Company may
                freely assign this Agreement. The terms and conditions set forth
                in this Agreement shall be binding upon assignees.
              </li>
              <li className="liNumbered">
                <b>Trademark Information.&nbsp;&nbsp;</b> All trademarks, logos
                and service marks (<b>“Marks”</b>) displayed in connection with
                the Services are our property or the property of other third
                parties. You are not permitted to use these Marks without our
                prior written consent or the consent of such third party which
                may own the Marks.
              </li>
              <li className="liNumbered">
                <b>Force Majeure.&nbsp;&nbsp;</b> Neither party will be
                responsible to the other for any delay in performing under this
                Agreement which results from civil disturbance, undeclared or
                declared war or other hostilities, acts of terrorism, acts of
                anarchy, labor strikes or interruptions, earthquakes or other
                acts of Nature or acts of God, governmental orders, diseases,
                pandemics, or any cause beyond the reasonable control of such
                party.
              </li>
              <li className="liNumbered">
                <b>Contact Information:</b>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    Triptage Inc.
                    <br />
                    117 E. Louisa Street #361
                    <br />
                    Seattle, WA 98102
                    <br />
                    USA
                    <br />
                    Email:&nbsp;
                    <a href="mailto:legal@triptage.com">legal@triptage.com</a>
                  </div>
                </div>
              </li>
            </ol>
          </li>
        </ol>
      </Stack>
    </div>
  );
}

export default TermsAndConditions;
