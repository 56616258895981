import { RouterConstants } from 'constants/RouterConstants';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RouterConstants.PRIVACY_POLICY_PATH}>
          <PrivacyPolicy />
        </Route>
        <Route path={RouterConstants.TERMS_AND_CONDITIONS_PATH}>
          <TermsAndConditions />
        </Route>
        <Route path={RouterConstants.HOME_PATH}>
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default AppRoutes;
